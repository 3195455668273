import { SearchIcon } from '../Icons';

import { Wrapper } from './styles';
import { i18n } from './constants';
import { useLogic } from './useLogic';

type SearchFieldProps = {
  placeholder?: string;
  value?: string;
  onSetValue?: (value: string) => void;
  hideHelperText?: boolean;
};

export const SearchField = ({
  placeholder = i18n.defaultPlaceholder,
  hideHelperText,
  ...props
}: SearchFieldProps) => {
  const { handlerSearch, helperText, isError, innerValue } = useLogic(props);

  return (
    <Wrapper
      size="small"
      $hideHelperText={hideHelperText}
      type="text"
      spellCheck={false}
      placeholder={placeholder}
      value={innerValue}
      onChange={handlerSearch}
      InputProps={{
        startAdornment: <SearchIcon height={14} width={14} />,
      }}
      error={isError}
      helperText={helperText}
    />
  );
};
