import { type ReactNode } from 'react';
import {
  Avatar,
  OverflowTypography,
  Skeleton,
  Tooltip,
  Typography,
} from '@common/shared/components';
import {
  DateService,
  type DateString,
  type WithJSXChildren,
} from '@common/shared';

import {
  ArticleLeft,
  ArticleRight,
  AvatarContainer,
  ListItem,
  MessageLeft,
  MessageRight,
  MetaContainer,
} from './styles';
import { LoadState, StateContainer } from './LoadState';
import { type FileItem, FilesPreview } from './FilePreview';

export type Message = {
  /**
   * флаг, отображающий что сообщение улетает на бэк
   */
  isLoading?: boolean;
  /**
   * текст сообщения
   */
  text?: string | null;
  /**
   * дата отправки сообщения
   */
  sentTime: DateString;
  /**
   * мета информация об авторе
   */
  author: {
    /**
     * айди автора, который будет сравниваться с айди текущего пользователя
     */
    id: string;
    /**
     * полное имя пользователя
     */
    name: string;
    /**
     * дополнительный текст
     */
    supText?: string | null;
    /**
     * адрес к аватарке пользователя
     */
    avatar?: string | null;
  };
  /**
   * список файлов, прикрепленных к сообщению
   */
  files?: FileItem[];
};

type MessageVariantProps = {
  variant?: 'common' | 'personal';
} & WithJSXChildren;

const Article = ({ variant, children }: MessageVariantProps) => {
  if (variant === 'common') {
    return <ArticleLeft>{children}</ArticleLeft>;
  }

  return <ArticleRight>{children}</ArticleRight>;
};

const MessageComment = ({ variant, children }: MessageVariantProps) => {
  if (variant === 'common') {
    return <MessageLeft>{children}</MessageLeft>;
  }

  return <MessageRight>{children}</MessageRight>;
};

type MessageWrapperProps = MessageVariantProps & {
  title: ReactNode;
  supText: ReactNode;
  state: ReactNode;
  avatar: ReactNode;
};

const MessageWrapper = ({
  variant = 'common',
  supText,
  state,
  title,
  avatar,
  children,
}: MessageWrapperProps) => (
  <ListItem>
    <Article variant={variant}>
      <AvatarContainer>{avatar}</AvatarContainer>
      <MetaContainer>
        {title}
        {supText}
      </MetaContainer>
      <MessageComment variant={variant}>
        <>{children}</>
        <StateContainer>{state}</StateContainer>
      </MessageComment>
    </Article>
  </ListItem>
);

export const MessageSkeleton = () => (
  <MessageWrapper
    title={<Skeleton width={200} />}
    supText={<Skeleton width={250} />}
    state={<Skeleton width={80} />}
    avatar={<Skeleton variant="circular" width={40} height={40} />}
  >
    <Skeleton width={250} />
  </MessageWrapper>
);

type MessageItemProps = Message & {
  /**
   * айди текущего пользователя для определения того, что автор сообщения и есть текущий пользователь
   */
  currentUserId?: string;
  /**
   * флаг отображающий, что сообщение выгружается на бэк
   */
  isLoading?: boolean;
};

export const MessageItem = ({
  author,
  currentUserId,
  text,
  sentTime,
  isLoading,
  files,
}: MessageItemProps) => {
  const variant = author.id === currentUserId ? 'personal' : 'common';

  return (
    <MessageWrapper
      variant={variant}
      avatar={<Avatar name={author.name} src={author.avatar} />}
      title={
        <OverflowTypography component="cite">{author.name}</OverflowTypography>
      }
      supText={
        <OverflowTypography color="grey" colorIntensity="600">
          {author.supText}
        </OverflowTypography>
      }
      state={
        <>
          <Tooltip title={DateService.toConvenientByLocal(sentTime)}>
            <Typography
              color="grey"
              colorIntensity="600"
              variant="small"
              component="time"
            >
              {DateService.toSmartValueByLocal(sentTime)}
            </Typography>
          </Tooltip>
          <LoadState isLoading={isLoading} variant={variant} />
        </>
      }
    >
      <FilesPreview files={files} />
      <Typography variant="ui">{text}</Typography>
    </MessageWrapper>
  );
};
