import { forwardRef } from 'react';
import { type ToggleButtonProps } from '@mui/material';

import { Tooltip, type TooltipProps } from '../Tooltip';
import { ToggleButton } from '../ToggleButton';

type TooltipedToggleButtonProps = ToggleButtonProps & {
  tooltipProps: Omit<TooltipProps, 'children'>;
};

export const TooltipedToggleButton = forwardRef<
  HTMLButtonElement,
  TooltipedToggleButtonProps
>(({ tooltipProps, ...props }, ref) => (
  <Tooltip {...tooltipProps} disableInteractive>
    <ToggleButton {...props} ref={ref} />
  </Tooltip>
));
