import { type PropsWithChildren, type ReactNode, forwardRef } from 'react';

import {
  CardActions,
  CardContainer,
  CardDescription,
  CardText,
  CardTitle,
  CardWrapper,
} from './styles';

type DescriptionCardArticleProps = PropsWithChildren<{
  title: string;
  description?: string;
  isActionable?: boolean;
  actionChildren: ReactNode;
  hidePersonalData?: boolean;
}>;

export const DescriptionCardArticle = forwardRef<
  HTMLDivElement,
  DescriptionCardArticleProps
>(
  (
    {
      title,
      description,
      children,
      isActionable,
      actionChildren,
      hidePersonalData,
    },
    ref,
  ) => (
    <CardWrapper ref={ref} $isActionable={isActionable}>
      <CardText>
        <CardTitle
          color="grey"
          colorIntensity={description ? '700' : '900'}
          noWrap={Boolean(description)}
          component="h3"
        >
          {title}
          {description && ':'}
        </CardTitle>
        {description && (
          <CardDescription
            color="grey"
            colorIntensity="900"
            hidePersonalData={hidePersonalData}
          >
            {description}
          </CardDescription>
        )}
      </CardText>
      <CardActions>{actionChildren}</CardActions>

      {children && <CardContainer>{children}</CardContainer>}
    </CardWrapper>
  ),
);
