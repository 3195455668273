import {
  type ApiBeError,
  ApiError,
  createAxiosInstance,
  handleAxiosError,
} from '@common/shared';
import { type AxiosError } from 'axios';

import { logoutUnauthorizedInterceptor } from './axios';

const MUTEX_REGEXP = /Mutex для sessionID/;
const DEFAULT_RETRY_COUNT = 3;

export const { axiosClient, interceptors, setupInterceptors } =
  createAxiosInstance({});

interceptors.onRequest((data) => data, logoutUnauthorizedInterceptor);

interceptors.onResponse(
  (r) => r,
  (error: AxiosError) => {
    const data =
      (error.response?.data as ApiBeError)?.responseException ||
      error.response?.data;
    const retry = (error as { config: { retry?: number } }).config.retry ?? 0;

    if (
      typeof data === 'string' &&
      (data as string).match(MUTEX_REGEXP) &&
      retry < DEFAULT_RETRY_COUNT
    ) {
      return axiosClient.instance({
        ...error.config,
        ...{ retry: retry + 1 },
        timeout: 200,
      });
    }

    return logoutUnauthorizedInterceptor(error).catch((e) => {
      handleAxiosError(error, retry);

      return Promise.reject(ApiError.normalize(e));
    });
  },
);
