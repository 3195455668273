import { type RefObject, useId } from 'react';
import { Placeholder } from '@common/shared/components';
import Letter from '@common/shared/components/Icons/Letter.svg';

import { type Message, MessageItem, MessageSkeleton } from '../Message';

import { i18n } from './constants';
import { List, ListContainer } from './styles';
import { FetchMoreElement } from './FetchMoreElement';

export type MessagesListProps = {
  isLoading: boolean;
  currentUserId?: string;
  data?: Message[];
  isEndReached?: boolean;
  fetchMore: () => void;
  scrollRef: RefObject<HTMLDivElement>;
  className?: string;
};

export const MessagesList = ({
  isLoading,
  data,
  currentUserId,
  fetchMore,
  isEndReached,
  scrollRef,
  className,
}: MessagesListProps) => {
  const id = useId();

  return (
    <ListContainer ref={scrollRef} className={className}>
      {!isLoading && !data?.length && (
        <Placeholder title={i18n.noDataTitle} imgSrc={Letter} size="medium" />
      )}
      <List>
        {data?.map((comment, index) => (
          <MessageItem
            key={`${id}_${index}`}
            {...comment}
            currentUserId={currentUserId}
          />
        ))}
        {isLoading && !data?.length && <MessageSkeleton key="loading" />}
        {!isEndReached && data?.length ? (
          <FetchMoreElement
            scrollRef={scrollRef}
            fetchMore={fetchMore}
            isLoading={isLoading}
          />
        ) : null}
      </List>
    </ListContainer>
  );
};
