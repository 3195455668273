import { styled } from '@common/shared/components';

import { MessagesList } from './MessagesList';

export const Container = styled('div')`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

export const MessagesListWrapper = styled(MessagesList)`
  padding: ${({ theme }) => theme.spacing(8)};
`;
