import { type AxiosError } from 'axios';
import { errorMonitoringService } from '@services/ErrorMonitoringService';
import { type ApiBeError } from '@services/http/ApiError';

import { parseUrlToTags } from '../parsUrlToTags';

type ExcludeItem = {
  code: string;
  endpoint: RegExp;
};

const exlcude: ExcludeItem[] = [
  {
    code: '400',
    endpoint: new RegExp(/ConfirmationViaSms/),
  },
  {
    code: '401',
    endpoint: new RegExp(/api\/v\d/),
  },
  {
    code: '404',
    endpoint: new RegExp(/TelegramRegistrations\/Last/),
  },
  {
    code: '404',
    endpoint: new RegExp(/PlanningProcesses\/Active/),
  },
];

export const handleAxiosError = (error: AxiosError, retry?: number) => {
  const { response, config, request, ...otherError } = error;

  const urlTags = parseUrlToTags(config?.url);

  const errorCode = response?.status;

  if (
    exlcude.some(
      ({ code, endpoint }) =>
        String(errorCode) === code && String(urlTags.endpoint).match(endpoint),
    )
  ) {
    return;
  }

  errorMonitoringService.capture(
    Error(
      `Axios error: ${config?.method?.toUpperCase()} \nmicroservice:${String(
        urlTags.microservice,
      )} \nendpoint: ${String(urlTags.endpoint)} \ncode: ${errorCode}\nresponse:${JSON.stringify(
        (response?.data as ApiBeError)?.responseException ||
          response?.data ||
          'no response',
      )}${retry ? '\nretry:' + retry : ''}`,
    ),
    {
      extra: {
        ...otherError,
        response,
        config,
        request,
      },
      tags: {
        ['errorCode']: errorCode,
        ['method']: config?.method?.toUpperCase(),
        ...urlTags,
      },
    },
  );
};
