import { CheckIcon } from '@common/shared/components';

import { SpinnerWrapper, StateInner } from './styles';

type LoadStateProps = {
  isLoading?: boolean;
  variant: 'common' | 'personal';
};

export const LoadState = ({ variant, isLoading }: LoadStateProps) => {
  if (variant === 'common') {
    return null;
  }

  if (isLoading) {
    return (
      <StateInner>
        <SpinnerWrapper size="small" />
      </StateInner>
    );
  }

  return (
    <StateInner>
      <CheckIcon viewBox="2 2 12 12" />
    </StateInner>
  );
};
