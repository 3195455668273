import { type PageHeaderTemplate } from './types';

type Templates =
  //надоело придумывать названия, которые все равно сложно читать, пусть будет простое кодовое имя
  | 'echo'
  | 'titleActionsUnderActionsBelow'
  | 'backTitleActionsUnder'
  | 'backTitleBelow'
  | 'titleActionsUnder'
  | 'actionsBelowActionsUnder'
  | 'backTitleActionsUnderChildren'
  | 'backTitleActionsBelowChildrenWithTag'
  | 'backTitleTagBelow'
  | 'titleWithSubtitleActionsUnderActionsBelow';

const ONE_FR_AUTO = '1fr auto';
const AUTO_ONE_FR = 'auto 1fr';
const AUTO_1FR_AUTO = 'auto 1fr auto';
const AUTO_1FR_AUTO_AUTO = 'auto 1fr auto auto';

export const PAGE_HEADER_ELEMENTS = {
  back: 'back',
  title: 'title',
  tag: 'tag',
  subtitle: 'subtitle',
  actionUnder: 'actionUnder',
  actionBelow: 'actionBelow',
  actionUnderBelow: 'actionUnderBelow',
  children: 'children',
};

const {
  back,
  title,
  tag,
  subtitle,
  actionBelow,
  actionUnder,
  children,
  actionUnderBelow,
} = PAGE_HEADER_ELEMENTS;

export const PAGE_HEADER_TEMPLATES: Record<Templates, PageHeaderTemplate> = {
  backTitleActionsUnder: {
    areas: `'${back} ${title} ${actionUnder}'`,
    columns: AUTO_1FR_AUTO,
  },
  backTitleBelow: {
    areas: `'${back} ${title}' '${actionBelow} ${actionBelow}'`,
    columns: AUTO_ONE_FR,
  },
  backTitleTagBelow: {
    areas: `'${back} ${title} ${tag}' '${actionBelow} ${actionBelow} ${actionBelow}'`,
    columns: 'auto auto 1fr',
  },
  titleActionsUnderActionsBelow: {
    areas: `'${title} ${actionUnder}' '${actionBelow} ${actionBelow}'`,
    columns: ONE_FR_AUTO,
  },
  titleWithSubtitleActionsUnderActionsBelow: {
    areas: `'${title} ${actionUnder}' '${subtitle} ${subtitle}' '${actionBelow} ${actionUnderBelow}'`,
    columns: ONE_FR_AUTO,
  },
  titleActionsUnder: {
    areas: `'${title} ${actionUnder}'`,
    columns: ONE_FR_AUTO,
  },
  actionsBelowActionsUnder: {
    areas: `'${actionBelow} ${actionUnder}'`,
    columns: ONE_FR_AUTO,
  },
  backTitleActionsUnderChildren: {
    areas: `'${back} ${title} ${actionUnder}' '${children} ${children} ${children}'`,
    columns: AUTO_1FR_AUTO,
  },
  echo: {
    areas: `'${title} ${tag} ${actionUnder}' '${actionBelow} ${actionBelow} ${actionUnderBelow}'`,
    columns: AUTO_1FR_AUTO,
  },
  backTitleActionsBelowChildrenWithTag: {
    areas: `'${back} ${title} ${tag} ${actionUnder}' '${actionBelow} ${subtitle} ${children} ${children}'`,
    columns: AUTO_1FR_AUTO_AUTO,
  },
};
